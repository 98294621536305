import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"


const Page = () => {
  const metadata = {
    title: "Man Sues Girlfriend After Falling On Ice",
    heroH1: "Man Sues Girlfriend After Falling On Ice",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>
              Overcoming Suspicion, Cheshire Lawyer Clinches Settlement for Man
              Who Sued Girlfriend After Falling on Ice
            </h2>
            <p className="mb-8">
              CHESHIRE PLAINTIFFS COUNSEL FRANK BARTLETT HELPED SECURE A
              $250,000 SETTLEMENT FOR HIS CLIENT, WHO SUED HIS GIRLFRIEND, AFTER
              FALLING ON ACCUMULATED ICE IN HER DRIVEWAY.
            </p>
            <p className="mb-8">
              BY <strong>ROBERT STORACE</strong> | JULY 01, 2020
            </p>
            <div className="mb-8">
                  <StaticImage
                    quality="100"
                    src="../images/Davanand-Shiwgobin-Article-202007011201.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Security surveilance footage of man slipping and falling on ice"
                  />
                </div>
            <p className="mb-8">
              Davanand Shiwgobin, who fell on ice accumulation on a driveway on
              his girlfriend’s property, and recently settled the case for
              $250,000. When plaintiff Davanand Shiwgobin sued his girlfriend
              for falling on accumulated ice on her driveway, the defense team
              thought the fix was in…{" "}
              <a
                href="https://www.bartlettlegalgroup.com/firm-overview/news-archive/"
                target="_blank"
                rel="noreferrer"
              >
                Read the entire news article here Courtesy of the Connecticut
                Law Tribune.
              </a>
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
